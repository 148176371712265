import React, { useState } from "react";
import axios from "../../services/axios/Axios";

export default function Index() {
  const [revenueCatId, setRevenueCatId] = useState("");

  return (
    <div>
      <label>Notification Incomplete profile D+4 / D+21</label>
      <button
        disabled
        onClick={() => {
          axios.get("/scheduler/testNotifImperfectProfile").then((res) => {
            console.log("Response:", res);
          });
        }}
      >
        trigger
      </button>
      {/*  */}
      <br />
      <label>Notification Match no messages</label>
      <button
        disabled
        onClick={() => {
          axios.get("/scheduler/testMatchNoMessages").then((res) => {
            console.log("Response:", res);
          });
        }}
      >
        trigger
      </button>
      {/*  */}
      <br />
      <label>Notification inactive like incentive</label>
      <button
        disabled
        onClick={() => {
          axios
            .get("/scheduler/inactiveLikeIncentiveNotification")
            .then((res) => {
              console.log("Response:", res);
            });
        }}
      >
        trigger
      </button>
      {/*  */}
      <br />
      <label>Seed deleted column DB</label>
      <button
        disabled
        onClick={() => {
          axios.get("/profiles/seedDeletedUser").then((res) => {
            console.log("Response:", res);
          });
        }}
      >
        trigger
      </button>
      {/*  */}
      <br />
      <label>Seed senderId notifications</label>
      <button
        disabled
        onClick={() => {
          axios.get("/notifications/seedSenderId").then((res) => {
            console.log("Response:", res);
          });
        }}
      >
        trigger
      </button>
      {/*  */}
      <br />
      <label>Update %EX% roles</label>
      <button
        disabled
        onClick={() => {
          axios.get("/profiles/updateExProfilesRoles").then((res) => {
            console.log("Response:", res);
          });
        }}
      >
        trigger
      </button>
      {/*  */}
      <br />
      <label>Update One signal tags</label>
      <button
        disabled
        onClick={() => {
          axios.get("/profiles/updateOnesignalSendinblueTags").then((res) => {
            console.log("Response:", res);
          });
        }}
      >
        trigger
      </button>
      {/*  */}
      <br />
      <label>Update Profiles phoneNumber Helpee</label>
      <button
        disabled
        onClick={() => {
          axios.get("/profiles/updateProfilesHelpeePhoneNumber").then((res) => {
            console.log("Response:", res);
          });
        }}
      >
        trigger
      </button>
      {/*  */}
      <br />
      <label>Seed Automation Incentive Like</label>
      <button
        disabled
        onClick={() => {
          axios.get("/profiles/createAutomationIncentiveLike").then((res) => {
            console.log("Response:", res);
          });
        }}
      >
        trigger
      </button>
      {/*  */}
      <br />
      <label>Seed Revenue Cat id</label>
      <button
        onClick={() => {
          axios.get("/users/seed-revenue-cat-id").then((res) => {
            console.log("Response:", res);
          });
        }}
      >
        trigger
      </button>
      {/*  */}
      <br />
      <label>Get sockets clients connected</label>
      <button
        onClick={() => {
          axios.get("/twilio/get-clients-connected").then((res) => {
            console.log("Response:", res);
          });
        }}
      >
        trigger
      </button>
      {/*  */}
      <br />
      <label>Get revenue cat user subscription</label>
      <input
        type="revenueCatId"
        value={revenueCatId}
        onChange={(e) => setRevenueCatId(e.target.value)}
      />
      <button
        onClick={() => {
          axios
            .get(`subscriptions-events/revenue-cat-subscriber/${revenueCatId}`)
            .then((res) => {
              console.log("Response:", res);
            });
        }}
      >
        trigger
      </button>
    </div>
  );
}
