import React, { memo } from "react";

/*const [state, setState] = React.useState(isHighlighted ? {
  textStyle: [styles.textHighlight, {color: props.hTextColor ?? 'white'}],
  highlightColor: props.color,
  borderColor: props.color,
} : {
  textStyle: styles.textMute,
  highlightColor: backColor,
  borderColor: Colors.PRIMARY_GREY,
});*/

const HighlightSwitch = ({ isHighlighted, labelStyle, ...props }) => {
  const highlightColor = isHighlighted ? "#47B9A8" : "#C4C4C4";
  let borderColor = "black";
  return (
    <div
      style={{
        backgroundColor: highlightColor,
        borderColor: borderColor,
        flexDirection: "column",
        fontSize:11,
        fontWeight:"500",
        alignItems: "center",
        height: "30px",
        width: "150px",
        borderRadius: 20,
        margin: 10,
        textAlign: "center",
        justifyContent: "center",
        verticalAlign: "middle",
        alignContent: "center",
        display:"inline-flex",
      }}
      
      onClick={(e, value, info, data) => props.onClick(e, value, info, data)}
    >
      
      <div style={labelStyle}>{props.text}</div>
      
    </div>
    
  );
};

export default HighlightSwitch;
